import styled from "styled-components";
var __makeTemplateObject =
	(this && this.__makeTemplateObject) ||
	function (cooked, raw) {
		if (Object.defineProperty) {
			Object.defineProperty(cooked, "raw", { value: raw });
		} else {
			cooked.raw = raw;
		}
		return cooked;
	};
export var WheelCanvasStyle = styled.canvas(
	templateObject_1 ||
		(templateObject_1 = __makeTemplateObject(
			["\n  width: 98%;\n  height: 98%;\n"],
			["\n  width: 98%;\n  height: 98%;\n"]
		))
);
var templateObject_1;
