import React, { useState, useEffect } from "react";
import EditableList from "../editable-list";

import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { v4 as uuidv4 } from "uuid";
import { Wheel } from "../wheel2";
// import Wheel from "./controlWheel";
import { useChain, useSpring, useSpringRef, animated } from "@react-spring/web";
import { useQueryParam } from "use-query-params";
import CommaArrayParam from "./commaArrayParam.ts";

import "./App.scss";

/*
TODO: 
- Make sure user enters divisible by 2 people
- animate shuffle into the pairs

- add entrance password 
- add your name 
- You can't spin the wheel until everyone is online (small font in upper right corner of the people online)
- it's cooler if we all do it together 
- like Jackbox, once everyone says they're ready, we go next

- everyone has to accept the decision or it spins again

when you hit pair up, those names shuffle into the pairs 

color change so each pair is a color (red, blue, green?)
then drink animation pours out a cup 
everyone has to accept the decision or it shuffles again 

spits out image shareable which has the liquor and pairs


*/

const initialDrinks = [
	"Rum",
	"Tequila",
	"Gin",
	"Mezcal",
	"Bourbon",
	"Scotch",
	"Irish Whiskey",
];

function DrinksList(props) {
	useChain(props.chainSprings);
	const drinkList = props.drinkList;
	return (
		<animated.div
			style={{
				...props.step2FadeInSprings,
				textAlign: "center",
				paddingLeft: 0,
				listStyle: "none",
			}}
		>
			<animated.div
				style={{
					...props.step2FadeOutSprings,
					...props.step2HideSprings,
				}}
			>
				<EditableList
					list={drinkList.map(({ name }) => name)}
					onListChange={props.onDrinkListChange}
					placeholder={"drink name"}
					addItemPlaceholder={"add drink"}
					showBullets={false}
				/>
				<Button variant="secondary" onClick={props.handlePickDrinksClick}>
					next
				</Button>
			</animated.div>
		</animated.div>
	);
}

const App = () => {
	const [players, setPlayersQuery] = useQueryParam("players", CommaArrayParam);
	var initialPlayerList = [];
	if (players) {
		initialPlayerList = players.map((player) => ({
			name: player,
			id: uuidv4(),
		}));
	}
	const [drinks, setDrinksQuery] = useQueryParam("drinks", CommaArrayParam);
	const drinksArray = drinks ? drinks : initialDrinks;

	const initialDrinkList = drinksArray.map((drink) => ({
		name: drink,
		id: uuidv4(),
	}));

	const [playerList, setPlayerList] = React.useState(initialPlayerList);
	const [drinkList, setDrinkList] = React.useState(initialDrinkList);
	const [currentStep, setCurrentStep] = React.useState(1);

	const onPlayerListChange = (newList) => {
		setPlayersQuery(newList);
		const newPlayerList = newList.map((playerName) => {
			return { name: playerName, id: uuidv4() };
		});
		setPlayerList(newPlayerList);
	};
	const onDrinkListChange = (newList) => {
		setDrinksQuery(newList);
		const newDrinkList = newList.map((drinkName) => {
			return { name: drinkName, id: uuidv4() };
		});
		setDrinkList(newDrinkList);
	};
	const handlePickDrinksClick = () => {
		setCurrentStep(3);
	};

	const handleNamesNextClick = () => {
		// pick a random person
		var newPlayerList = [];
		for (let i = 0; newPlayerList.length !== playerList.length; i++) {
			const randomPerson =
				playerList[Math.floor(Math.random() * playerList.length)];
			if (!newPlayerList.find((element) => element.id === randomPerson.id)) {
				// add person to new array
				newPlayerList.push(randomPerson);
			}
		}
		setPlayerList(newPlayerList);
		setCurrentStep(2);
	};

	const [step1ShowSprings, step1ShowApi] = useSpring(() => ({
		from: { opacity: 0 },
		to: { opacity: 1 },
	}));
	const spring1FadeOutRef = useSpringRef();
	const [step1FadeOutSprings, step1FadeOutApi] = useSpring(() => ({
		ref: spring1FadeOutRef,
		from: { opacity: 1 },
		to: { opacity: 0 },
	}));
	const spring1HideRef = useSpringRef();
	const [step1HideSprings, step1HideApi] = useSpring(() => ({
		ref: spring1HideRef,
		from: { display: "block" },
		to: { display: "none" },
		immediate: false,
	}));

	const spring2FadeInRef = useSpringRef();
	const [step2FadeInSprings, step2Api] = useSpring(() => ({
		ref: spring2FadeInRef,
		from: { opacity: 0, display: "none" },
		to: { opacity: 1, display: "block" },
	}));
	const spring2FadeOutRef = useSpringRef();
	const [step2FadeOutSprings, step2FadeOutApi] = useSpring(() => ({
		ref: spring2FadeOutRef,
		from: { opacity: 1 },
		to: { opacity: 0 },
		immediate: false,
	}));

	const spring2HideRef = useSpringRef();
	const [step2HideSprings, step2HideApi] = useSpring(() => ({
		ref: spring2HideRef,
		from: { display: "block" },
		to: { display: "none" },
		immediate: false,
	}));

	const spring3FadeInRef = useSpringRef();
	const [step3Springs, step3Api] = useSpring(() => ({
		ref: spring3FadeInRef,
		from: { opacity: 0 },
		to: { opacity: 1, display: "block" },
	}));

	const [drinkNameSpring, drinkNameSpringApi] = useSpring(() => ({
		from: { opacity: 0 },
	}));

	function RouletteWheel(props) {
		useChain([spring2FadeOutRef, spring2HideRef, spring3FadeInRef]);
		const [mustSpin, setMustSpin] = useState(false);
		const [prizeNumber, setPrizeNumber] = useState(0);
		const [newPairs, setNewPairs] = useState([]);
		const [drinkOptions, setDrinkOptions] = useState([]);
		function pickWinner() {
			const newPrizeNumber = Math.floor(Math.random() * props.drinkList.length);
			setPrizeNumber(newPrizeNumber);
			console.log("picked prize number", newPrizeNumber);
		}

		useEffect(() => {
			setTimeout(setMustSpin(true), 500);
		}, []);
		useEffect(() => {
			var newPairs = [];
			const currentPairs = props.playerList;
			for (let i = 0; i < currentPairs.length; i = i + 2) {
				newPairs.push(
					<li key={currentPairs[i].id}>
						{currentPairs[i].name} & {currentPairs[i + 1].name}
					</li>
				);
			}
			setNewPairs(newPairs);
		}, [props.playerList]);
		useEffect(() => {
			const drinkOptions = Array.from(props.drinkList, (x) => {
				return {
					option: x.name,
				};
			});
			setDrinkOptions(drinkOptions);
			pickWinner();
			console.log(props.drinkList);
		}, []);

		return (
			<>
				<animated.div
					style={{
						...step3Springs,
					}}
				>
					{currentStep !== 1 && (
						<>
							<ul
								style={{
									paddingLeft: 0,
									listStyle: "none",
									marginBottom: "0",
								}}
							>
								{newPairs}
							</ul>
						</>
					)}
					<Wheel
						mustStartSpinning={mustSpin}
						prizeNumber={prizeNumber}
						data={drinkOptions}
						onStopSpinning={() => {
							setMustSpin(false);
							drinkNameSpringApi.start({ to: { opacity: 1 } });
						}}
					/>
				</animated.div>
				<animated.div
					style={{
						...step3Springs,
					}}
				>
					and the winning drink is...{" "}
					<animated.div
						style={{
							...drinkNameSpring,
						}}
					>
						<span
							style={{
								textTransform: "uppercase",
							}}
						>
							{props.drinkList[prizeNumber].name}
						</span>
						!
					</animated.div>
					<Button
						style={{ marginTop: "10px", fontWeight: "bold" }}
						disabled={mustSpin}
						variant="dark"
						onClick={() => {
							drinkNameSpringApi.start({ to: { opacity: 0 } });
							setTimeout(() => {
								pickWinner();
								setMustSpin(true);
							}, 300);
						}}
					>
						spin again
					</Button>
				</animated.div>
			</>
		);
	}

	return (
		<Container className="p-3">
			<Row>
				<Col></Col>
				<Col md="auto">
					<h1 style={{ textAlign: "center" }}>WELCOME TO DRINK ROULETTE</h1>
					<>
						<animated.div
							style={{
								...step1ShowSprings,
								...step1HideSprings,
								textAlign: "center",
							}}
						>
							<animated.div
								style={{
									...step1FadeOutSprings,
								}}
							>
								<h2>name your players</h2>
								<EditableList
									list={playerList.map((player) => player.name)}
									onListChange={onPlayerListChange}
									placeholder={"name"}
									addItemPlaceholder={"enter to add"}
									showBullets={false}
								/>
								<Button
									style={{ marginTop: "10px", fontWeight: "bold" }}
									variant="outline-secondary"
									onClick={handleNamesNextClick}
								>
									next
								</Button>
							</animated.div>
						</animated.div>
					</>
					{currentStep !== 1 && (
						<DrinksList
							playerList={playerList}
							drinkList={drinkList}
							key="123456"
							onDrinkListChange={onDrinkListChange}
							handlePickDrinksClick={handlePickDrinksClick}
							chainSprings={[
								spring1FadeOutRef,
								spring1HideRef,
								spring2FadeInRef,
							]}
							step2HideSprings={step2HideSprings}
							step2FadeOutSprings={step2FadeOutSprings}
							step2FadeInSprings={step2FadeInSprings}
						/>
					)}
					{currentStep === 3 && (
						<div
							style={{
								display: "flex",
								textAlign: "center",
								flexDirection: "column",
								alignItems: "center",
							}}
						>
							<RouletteWheel drinkList={drinkList} playerList={playerList} />
						</div>
					)}
				</Col>
				<Col></Col>
			</Row>
		</Container>
	);
};

export default App;
